
$color-orange: #CF0033;
$color-orange-dark: #AF062F;
$color-primary: $color-orange;
$color-primary-dark: $color-orange-dark;
$gradient-primary: $color-orange;

@import "brand-settings";
@import "../app";

.brand-rossmann {
  .NavigationMain-title .TextLink {
    cursor: default;
  }
  .MetaNavigation-meta {
    .MetaNavigation-link .TextLink {
      color: $color-white;
    }
  }
  .TextLink--asButton {
    &.TextLink--buttonFilled:not(.TextLink--buttonWithoutLabel) {
      color: $color-white;
    }
  }
  .ButtonCustomerService {
    .Icon {
      color: $color-white;
    }
  }
}

.branded {
  .MetaNavigation-social,
  .NavigationMain-subnavi,
  .NavigationMain-mainnavi {
    display: none;
  }
}


